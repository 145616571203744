import type { JSX } from 'react';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

import { Ads as AdsComponent } from '@/components';

/**
 * Props for `Ads`.
 */
export type AdsProps = SliceComponentProps<Content.AdsSlice>;

/**
 * Component for "Ads" Slices.
 */
const Ads = ({ slice }: AdsProps): JSX.Element => <AdsComponent {...slice} />;

export default Ads;
